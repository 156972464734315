// extracted by mini-css-extract-plugin
export var copyright = "footer-module--copyright--e5100";
export var email_signup_header = "footer-module--email_signup_header--ab512";
export var error = "footer-module--error--29893";
export var error_svg = "footer-module--error_svg--abc79";
export var footerContainer = "footer-module--footerContainer--61a23";
export var importantLinks = "footer-module--importantLinks--7928e";
export var last_row = "footer-module--last_row--832ee";
export var logoImage = "footer-module--logoImage--092dd";
export var row = "footer-module--row--00182";
export var sign_up_form = "footer-module--sign_up_form--2b92b";
export var social_area = "footer-module--social_area--9018b";
export var social_area_icons = "footer-module--social_area_icons--dd127";
export var social_icons = "footer-module--social_icons--bb615";