import React from "react";
import { Link, navigate } from "gatsby";
import { map as _map } from "lodash";
import Button from "../button/Button";
import FacebookIcon from "@col-care/common/elements/icons/FacebookIcon";
import InstagramIcon from "@col-care/common/elements/icons/InstagramIcon";
import TwitterIcon from "@col-care/common/elements/icons/TwitterIcon";
import LinkedinIcon from "@col-care/common/elements/icons/LinkedinIcon";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import footerNav from "./footer.json";
import * as footerStyles from "./footer.module.css";

export const openInNewTab = (url) => {
  // 👇️ setting target to _blank with window.open
  window.open(url, "_blank");
};

const OhioFooter = (props) => {
  const posthog = props.posthog;

  const onClickHandler = (item) => {
    capturePosthogLink({
      eventName: posthogEvents.FOOTER_NAVIGATION,
      title: item.title,
      to: item.link,
      posthog,
    });
  };

  return (
    <footer>
      <div className={footerStyles.footerContainer}>
        <div className={footerStyles.row}>
          <a
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
              capturePosthogLink({
                eventName: posthogEvents.FOOTER_NAVIGATION,
                title: "Columbia Care",
                to: "/",
                posthog,
              });
            }}
            aria-label={"Columbia Care"}
          >
            <img
              className={footerStyles.logoImage}
              src="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt4504ca54f0ef5f60/62489b47c674a505586e09c6/columbia-care-icon.png"
              alt="Columbia Care"
              // placeholder="blurred"
              // critical="true"
              style={{ opacity: "100 !important" }}
            />
          </a>
        </div>
        <div className={footerStyles.row}>
          <ul>
            <li>Locations</li>
            {footerNav?.oh_comms?.locations_link?.map((item) => {
              return (
                <li key={item.title}>
                  {item.external ? (
                    <a
                      href={item.link}
                      target={item.external ? "_blank" : "_self"}
                      onClick={() => onClickHandler(item)}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.link} onClick={() => onClickHandler(item)}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className={footerStyles.row}>
          <ul>
            <li>Other Links</li>

            {footerNav?.oh_comms?.other_links?.map((item) => {
              return (
                <li key={item.title}>
                  {item.external ? (
                    <a
                      href={item.link}
                      target={item.external ? "_blank" : "_self"}
                      onClick={() => onClickHandler(item)}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.link} onClick={() => onClickHandler(item)}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={`${footerStyles.row} ${footerStyles.last_row}`}>
          <span className={footerStyles.email_signup_header}>
            Get Email & SMS Alerts
          </span>
          {/* <FooterSignUp {...props} /> */}
          <Button
            theme="footerButton"
            label="sign me up!"
            onClick={() => {
              const link = "https://lab.alpineiq.com/join/c/1275/1276";
              openInNewTab(link);
              capturePosthogLink({
                eventName:
                  posthogEvents.FOOTER_NAVIGATION +
                  " " +
                  posthogEvents.EXTERNAL_NAVIGATION,
                title: "Sign me up!",
                to: link,
                posthog,
              });
            }}
          />
        </div>
      </div>
      <div className={footerStyles.social_area}>
        <div className={footerStyles.social_area_icons}>
          {footerNav?.oh_comms?.social_nav?.map((item) => (
            <a
              key={item.title}
              href={item.link}
              target="_blank"
              rel="noreferrer"
              aria-label={item.title}
              onClick={() =>
                capturePosthogLink({
                  eventName: posthogEvents.SOCIAL_NAVIGATION,
                  title: item.title,
                  to: item.link,
                  posthog,
                })
              }
            >
              {item.iconName === "instagram" && (
                <InstagramIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "facebook" && (
                <FacebookIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "twitter" && (
                <TwitterIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
              {item.iconName === "linkedin" && (
                <LinkedinIcon
                  color={item.iconColor}
                  className={footerStyles.social_icons}
                />
              )}
            </a>
          ))}
          <a
            href="https://www.facebook.com/columbiacareohio"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <FacebookIcon color="#fff" className={footerStyles.social_icons} />
          </a>
          <a
            href="https://www.instagram.com/columbiacareohio/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <InstagramIcon color="#fff" className={footerStyles.social_icons} />
          </a>
          {/*<a
              href="https://twitter.com/cann_company"
              target="_blank"
              rel="noreferrer"
              aria-label="Twitter"
            >
              <TwitterIcon color="#fff" className={footerStyles.social_icons} />
            </a>
            <a
              href="https://www.linkedin.com/company/col-care"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
            >
              <LinkedinIcon
                color="#fff"
                className={footerStyles.social_icons}
              />
            </a>
            */}
        </div>
      </div>
      <div className={footerStyles.copyright}>
        &copy; {new Date().getFullYear()} Columbia Care | All Rights Reserved
      </div>
    </footer>
  );
};

export default OhioFooter;
