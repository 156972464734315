import { bindActionCreators } from "redux";
import { memoize as _memoize } from "lodash";

import HydrateActions from "../../../../common/redux/hydrate/actions";

export const createBoundActions = function (dispatch) {
  return {
    hydrate: bindActionCreators(HydrateActions, dispatch),
  };
};

export const getBoundActions =
  typeof window !== "undefined"
    ? _memoize(createBoundActions)
    : createBoundActions;
