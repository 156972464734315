
import {useEffect, useState} from 'react'

const cookies = () => {
  if (typeof document !== "object") { return [null, null] }
  return Object.fromEntries(document.cookie.split(';').map(it => {
    const [key, value] = it.split('=')
    return [key.trim(), value]
  })
)};

const cookieExist = (cookieName, match) => { 
  if (match === 'loose') {
    let cooky;
    if (!cookies()) { return false }
    Object.keys(cookies()).map((val) => {
      if (val && val.indexOf(cookieName) === 0) {
        cooky = !!cookies()[val]
        return
      }
    });
    return cooky;
  }
  else {
    return !!cookies()[cookieName]
  }
}

export const useCookieWatcher = (cookie, pollingRate = 250, match = 'exact') => {
  // state for cookie existence
  const [exist, setExist] = useState(cookieExist(cookie, match))

  useEffect(() => {
    const interval = setInterval(() => {
      const cookyExists = cookieExist(cookie, match)
      setExist(cookyExists)
    }, pollingRate)
    return () => clearInterval(interval)
  })

  return exist
}

export const useCookie = (cookie) => cookies()[cookie]