import React from "react";

import * as styles from "./Button.module.css";

const Button = (props) => {
  const { children } = props;
  return (
    <button className={styles.button} {...props}>
      {children}
    </button>
  );
};

export default Button;
