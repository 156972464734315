import React from "react";
import ReactModal from "react-modal";
import { getModalVariant } from "./variants";
import * as modalStyles from "./modal.module.css";

const Modal = (props) => {
  ReactModal.setAppElement("#___gatsby");

  const [children, styles] = getModalVariant(props);

  if (props.hide) {
    return null;
  }

  return (
    <ReactModal
      isOpen={true}
      onAfterOpen={() => { document.body.style.overflow = 'hidden' }}
      onRequestClose={() => {
        props.dismissModal();
      }}
      onAfterClose={() => document.body.style.overflow = 'unset'}
      style={styles}
      className={`${modalStyles.ModalWrapper} ${props.extraClasses}`}
      contentLabel={props.title || "Modal Title"}
      preventScroll={props.preventScroll}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      shouldFocusAfterRender={props.shouldFocusAfterRender}
    >
      <div>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.displayName = "Modal";

export default Modal;
