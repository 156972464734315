import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { decode } from "html-entities";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
// import { noop as _noop } from "lodash";

import "@reach/skip-nav/styles.css";

import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";

import Header from "./header/Header";
import Footer from "../components/footer/Footer";
import { getStructuredData } from "../utility";
import ProximaNovaUrl from "../fonts/proxima_ssv/ProximaNova-Regular.otf";
import "../styles/layout.css";

// const GTM_ID = 'GTM-KGXWKJF';
const image =
  "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt25caeed96a5bc714/62cc55c6181754349ea306ce/cc_card.png";

const Layout = React.memo((props) => {
  const state = useSelector((state) => state);
  const posthog = usePostHog();

  // const contentSquareRef = useRef();

  let pageContext = props.children?.props.pageContext;

  // this needs to not be here
  const containerStyle = { marginTop: "88px", minHeight: "704px" };

  const bannerData = state?.hydrate?.siteConfigData?.blocks?.[
    "interstitial--news-banner"
  ]
    ? state.hydrate.siteConfigData?.blocks["interstitial--news-banner"]
        .component_data
    : null;

  // no site config here means we're in Gatsby dev mode error

  const title =
    pageContext?.content?.seo?.meta_title?.length > 0
      ? decode(pageContext.content.seo.meta_title)
      : decode(pageContext.title);

  const desc =
    pageContext.content?.seo?.meta_description?.length > 0
      ? decode(pageContext.content.seo.meta_description)
      : decode(pageContext?.site_config?.seo?.meta_description);

  const metaTitle = pageContext.site_config
    ? title + " | " + decode(pageContext.site_config.seo.meta_title)
    : "";
  const metaDesc = pageContext.site_config ? desc : null;

  const metaRobots = pageContext.site_config
    ? decode(pageContext.site_config.seo.meta_robots)
    : null;

  const jsonLdLocalBusiness = pageContext.content
    ? getStructuredData("LocalBusiness", state, pageContext.content)
    : null;

  return (
    <>
      {!pageContext || !pageContext.site_config ? (
        <>
          <SkipNavLink />

          <Helmet
            htmlAttributes={{ lang: "en" }}
            encodeSpecialCharacters={false}
          >
            <meta charSet="utf-8" />
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <link
              rel="preload"
              as="font"
              href={ProximaNovaUrl}
              type="font/otf"
              crossOrigin="anonymous"
            />
          </Helmet>
        </>
      ) : (
        <>
          <Helmet
            htmlAttributes={{ lang: "en" }}
            encodeSpecialCharacters={false}
            title={`${metaTitle}`}
          >
            {jsonLdLocalBusiness && (
              <script type={jsonLdLocalBusiness.type}>
                {jsonLdLocalBusiness.innerHTML}
              </script>
            )}
            <meta charSet="utf-8" />
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <meta name="description" content={`${metaDesc}`} />
            <meta name="robots" content={`${metaRobots}`} />

            <meta property="og:title" content={`${metaTitle}`} />
            <meta property="og:description" content={`${metaDesc}`} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="800" />
            <meta property="og:image:height" content="418" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${metaTitle}`} />
            <meta name="twitter:site" content="@ColumbiaCare" />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:image:alt" content="Columbia Care" />
            <meta name="twitter:description" content={`${metaDesc}`} />

            <link
              rel="preload"
              as="font"
              href={ProximaNovaUrl}
              type="font/otf"
              crossOrigin="anonymous"
            />
          </Helmet>
        </>
      )}

      <Header bannerData={bannerData} posthog={posthog} />

      {/* {
        (
          () => {
            return (
              <span ref={ contentsquareRef }>
                <noscript>
                  <iframe 
                    src={ `https://www.googletagmanager.com/ns.html?id=${GTM_ID}` }
                    height="0" 
                    width="0" 
                    style={ 
                      {
                        display: 'none',
                        visibility: 'hidden' 
                      }
                    }
                  />
                </noscript>
              </span>
            );
          }
        )()
      } */}

      <div style={containerStyle}>
        <SkipNavContent />
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, { posthog })
        )}
      </div>

      <Footer posthog={posthog} />
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.displayName = "Layout";

export default Layout;
