import React, { useEffect, useState, useRef } from "react";
import { Link, navigate } from "gatsby";
import Cookies from "universal-cookie";
import clsx from "clsx";

import { GhubLink } from "@col-care/common/common";
import MenuBar from "../MenuBar";
import Button from "../Button";
import Banner from "../../banner/Banner";
//import useWindowDimensions from "./hooks/use-window-dimensions";
import DarkLogo from "./images/dark-logo.png";
import LightLogo from "./images/white-logo.png";
import ArrowIcon from "./images/svg/arrow.svg";
import * as styles from "./Navbar.module.scss";
import menu from "./data";
import ohmenu from "./ohdata";
import LocationIcon from "./LocationIcon";
import "./styles/menu.scss";

const Navbar = (props) => {
  const cookies =
    typeof document === "object" ? new Cookies(document.cookie) : {};
  const bannerRef = useRef(null);
  // const [show, setShow] = useState(true);
  const navigationMenu = [...menu];
  const ohnavigationMenu = [...ohmenu];
  const [isHome, setIsHome] = useState(false);
  const [onTop, setOnTop] = useState(true);
  const [currentlyVisible, setCurrentlyVisible] = useState();
  // const dimensions = useWindowDimensions();
  const isScroll = isHome ? !onTop : true;
  const iconColor = isScroll ? "black" : "white";
  const [image, setImage] = useState(
    "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt8c29123e3db00ec9/62991a6aae6f0e4156d198d1/About-Us.png"
  );
  const bannerEnabled =
    props.bannerData && props.bannerData.news_banner_config
      ? props.bannerData.news_banner_config.enabled
      : false;
  const [showBanner, setShowBanner] = useState(bannerEnabled);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [calculatedPosition, setCalculatedPosition] = useState(0);

  const cookyKey =
    props.bannerData && props.bannerData.news_banner_config
      ? "cc_banner::" + props.bannerData.news_banner_config.campaign_id
      : null;
  const actuallyShowBanner =
    props.bannerData &&
    props.bannerData.news_banner_config &&
    cookies.cookies &&
    cookies.cookies[cookyKey] === undefined &&
    props.bannerData.news_banner_config.enabled &&
    showBanner;

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setOnTop(winScroll <= 120);
  };
  function handleMouseEnter(imagePath) {
    return () => {
      setImage(imagePath);
    };
  }

  const handleClickMenu = (item) => {
    // Replace with router.push
    alert(item.value);
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", listenToScroll);
      setIsHome(true);

      const cb = () => {
        const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
        setBannerHeight(height);
        const _calculatedPosition =
          height - window.scrollY >= 0 ? height - window.scrollY : 0;
        setCalculatedPosition(_calculatedPosition);
      };
      window.addEventListener("scroll", cb);

      return () => {
        window.removeEventListener("scroll", listenToScroll);
        window.removeEventListener("scroll", cb);
      };
    }
  }, []);

  useEffect(() => {
    const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
    setBannerHeight(height);
    const _calculatedPosition =
      height - window.scrollY >= 0 ? height - window.scrollY : 0;
    setCalculatedPosition(_calculatedPosition);
  }, []);

  useEffect(() => {
    const cb = () => {
      const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
      setBannerHeight(height);
      const _calculatedPosition =
        height - window.scrollY >= 0 ? height - window.scrollY : 0;
      setCalculatedPosition(_calculatedPosition);
    };

    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
    };
  }, [bannerRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (bannerRef.current ? bannerRef.current.offsetHeight : 0) !==
        bannerHeight
      ) {
        const height = bannerRef.current ? bannerRef.current.offsetHeight : 0;
        setBannerHeight(height);
        const _calculatedPosition =
          height - window.scrollY >= 0 ? height - window.scrollY : 0;
        setCalculatedPosition(_calculatedPosition);
      }
    }, 25);
    return () => clearInterval(interval);
  }, []);

  function setCurrent(value) {
    setCurrentlyVisible(value);
  }

  function clearCurrent() {
    setCurrentlyVisible();
  }
  if (process.env.GHUB_DATADOG_SERVICE.indexOf("-oh-comms") > -1) {
    return (
      <>
        <div ref={bannerRef} className={styles.banner}>
          <Banner
            bannerData={props.bannerData}
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            actuallyShowBanner={actuallyShowBanner}
            cookyKey={cookyKey}
          />
        </div>
        <header
          style={actuallyShowBanner ? { top: calculatedPosition + "px" } : {}}
          className={clsx(styles.wrapper, isScroll && styles.dark)}
        >
          <div className={styles.container}>
            <div className={styles.logo}>
              <a
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                aria-label={"Columbia Care"}
                tabIndex={0}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={isScroll ? DarkLogo : LightLogo}
                  alt="Home"
                  aria-label="Home"
                  role="button"
                />
              </a>
            </div>
            <nav
              id={styles.ohNav}
              className={clsx(styles.navbar, isScroll && styles.dark)}
            >
              {ohmenu?.map((item) => (
                <React.Fragment key={item?.value}>
                  {item.items.length > 0 ? (
                    <div
                      className={clsx(styles.dropdown)}
                      onMouseEnter={() => {
                        setCurrent(item.value);
                      }}
                      onKeyDown={() => {
                        setCurrent(item.value);
                      }}
                      onMouseLeave={() => {
                        clearCurrent();
                      }}
                    >
                      <button to={item.href || "#"} className={styles.btn}>
                        {item?.value}
                        <ArrowIcon />
                      </button>
                      <div
                        onMouseLeave={() => {
                          clearCurrent();
                        }}
                        className={clsx(
                          styles.content,
                          currentlyVisible === item.value && styles.dropdownOpen
                        )}
                      >
                        <div>
                          <h3>{item?.value}</h3>
                          <div className={styles.gridWrapper}>
                            <div role="button">
                              <img
                                src={image}
                                alt="thumbnail values"
                                width="100%"
                              />
                            </div>
                            <div className={styles.grid}>
                              {item.items?.map((sub) => (
                                <div
                                  key={sub?.value}
                                  tabIndex="0"
                                  onMouseEnter={handleMouseEnter(
                                    sub?.thumbnail
                                  )}
                                  onClick={() => {
                                    clearCurrent();
                                    sub.href && navigate(sub.href);
                                  }}
                                  onKeyDown={() => {
                                    clearCurrent();
                                    sub.href && navigate(sub.href);
                                  }}
                                >
                                  <h4>
                                    <ArrowIcon />
                                    {sub?.value}
                                  </h4>
                                  <p>{sub?.content}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!item.mobileOnly && (
                        <GhubLink
                          label={item.value}
                          destination={item.href}
                          attributes={{
                            onMouseEnter: () => {
                              setCurrent(item.value);
                            },
                            onKeyDown: () => {
                              setCurrent(item.value);
                            },
                          }}
                          children={item.value}
                        />
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
              <div className={styles.show_mobile}>
                <MenuBar
                  animation={["slideIn", "slideOut"]}
                  backgroundColor={isScroll ? "white" : "rgba(0, 0, 0, 0.4)"}
                  iconColor={iconColor}
                  textColor={isScroll ? "rgba(0, 0, 0, 0.4)" : "white"}
                  onClick={handleClickMenu}
                  onKeyDown={handleClickMenu}
                  data={ohnavigationMenu}
                />
              </div>
            </nav>

            {/*<div className={styles.show_mobile}>
              <Link aria-label="Locations" to="/locations">
                <LocationIcon stroke={iconColor} />
              </Link>
            </div>
             */}
          </div>
        </header>
      </>
    );
  }

  return (
    // show && (
    <>
      <div ref={bannerRef} className={styles.banner}>
        <Banner
          bannerData={props.bannerData}
          showBanner={showBanner}
          setShowBanner={setShowBanner}
          actuallyShowBanner={actuallyShowBanner}
          cookyKey={cookyKey}
        />
      </div>
      <header
        style={actuallyShowBanner ? { top: calculatedPosition + "px" } : {}}
        className={clsx(styles.wrapper, isScroll && styles.dark)}
      >
        <div className={styles.container}>
          <div className={styles.logo}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              onKeyDown={() => navigate("/")}
              src={isScroll ? DarkLogo : LightLogo}
              alt="Home"
              aria-label="Home"
              tabIndex={0}
              role="button"
            />
          </div>
          <nav className={clsx(styles.navbar, isScroll && styles.dark)}>
            {menu?.map((item) => (
              <React.Fragment key={item?.value}>
                {item.items.length > 0 ? (
                  <div
                    className={clsx(styles.dropdown)}
                    onMouseEnter={() => {
                      setCurrent(item.value);
                    }}
                    onKeyDown={() => {
                      setCurrent(item.value);
                    }}
                    onMouseLeave={() => {
                      clearCurrent();
                    }}
                  >
                    <button to={item.href || "#"} className={styles.btn}>
                      {item?.value}
                      <ArrowIcon />
                    </button>
                    <div
                      onMouseLeave={() => {
                        clearCurrent();
                      }}
                      className={clsx(
                        styles.content,
                        currentlyVisible === item.value && styles.dropdownOpen
                      )}
                    >
                      <div>
                        <h3>{item?.value}</h3>
                        <div className={styles.gridWrapper}>
                          <div role="button">
                            <img
                              src={image}
                              alt="thumbnail values"
                              width="100%"
                            />
                          </div>
                          <div className={styles.grid}>
                            {item.items?.map((sub) => (
                              <div
                                key={sub?.value}
                                tabIndex="0"
                                onMouseEnter={handleMouseEnter(sub?.thumbnail)}
                                onClick={() => {
                                  clearCurrent();
                                  sub.href && navigate(sub.href);
                                }}
                                onKeyDown={() => {
                                  clearCurrent();
                                  sub.href && navigate(sub.href);
                                }}
                              >
                                <h4>
                                  <ArrowIcon />
                                  {sub?.value}
                                </h4>
                                <p>{sub?.content}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!item.mobileOnly && (
                      <GhubLink
                        label={item.value}
                        destination={item.href}
                        attributes={{
                          onMouseEnter: () => {
                            setCurrent(item.value);
                          },
                          onKeyDown: () => {
                            setCurrent(item.value);
                          },
                        }}
                        children={item.value}
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
            <div className={styles.show_mobile}>
              <MenuBar
                animation={["slideIn", "slideOut"]}
                backgroundColor={isScroll ? "white" : "rgba(0, 0, 0, 0.4)"}
                iconColor={iconColor}
                textColor={isScroll ? "rgba(0, 0, 0, 0.4)" : "white"}
                onClick={handleClickMenu}
                onKeyDown={handleClickMenu}
                data={navigationMenu}
              />
            </div>
          </nav>
          <Button
            aria-label="Locations"
            onClick={(e) => {
              navigate("/locations");
            }}
          >
            <LocationIcon size={22} />
            <div>Find a Dispensary</div>
          </Button>
          <div className={styles.show_mobile}>
            <Link aria-label="Locations" to="/locations">
              <LocationIcon stroke={iconColor} />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
