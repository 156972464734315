import clsx from "clsx";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { navigate } from "gatsby";

import * as styles from "./Sidebar.module.css";
import logoIcon from "./images/white-logo.png";
import LocationIcon from "../Navbar/LocationIcon";
import RightArrow from "../MenuBar/assets/RightArrow";
import LeftArrow from "../MenuBar/assets/LeftArrow";
import "./transitions.css";

import { GhubLink } from "../../../../../../common/common";

function is_external(url) {
  return url.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) != null;
}

const Cross = ({ onToggle }) => {
  return (
    <div
      onClick={() => {
        onToggle();
      }}
    >
      <div className={styles.crossedLine}></div>
      <div className={styles.crossedLine}></div>
    </div>
  );
};

const Item = ({ onMove, close, ...item }) => {
  const { value, items, isSubNavItem } = item;
  return (
    <div
      className={
        isSubNavItem ? styles.subNavItemWrapper : styles.navItemWrapper
      }
    >
      <button
        onClick={() => {
          if (!items?.length) {
            close();
            if (!is_external(`${item.href}`)) {
              navigate(`${item.href}`);
            } else {
              window.open(`${item.href}`);
            }
          }
        }}
      >
        <div
          className={styles.navItem}
          onClick={() => {
            if (items?.length) onMove(item);
          }}
        >
          <div>{value}</div>
          {!!items?.length && (
            <div className={styles.subitemsArrow}>
              <RightArrow />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

function useStack(initialValue = null) {
  const [stack, setStack] = React.useState([initialValue]);
  const top = stack.slice(-1)[0];

  function push(newItem) {
    setStack((prev) => [...prev, newItem]);
  }

  function pop() {
    if (stack.length > 1) setStack((prev) => prev.slice(0, -1));
  }

  function reset() {
    setStack([initialValue]);
  }

  return { stack, push, pop, top, reset };
}

function useSubmenu(items) {
  const {
    push: pushItems,
    pop: popItems,
    top: currentItems,
    reset: resetItems,
  } = useStack(items);
  const {
    push: pushParent,
    pop: popParent,
    top: currentParent,
    reset: resetParent,
  } = useStack(null);

  function moveToNext(sourceItem) {
    pushItems(sourceItem.items);
    pushParent(sourceItem);
  }

  function moveBack() {
    popItems();
    popParent();
  }

  function reset() {
    resetItems();
    resetParent();
  }

  const isCurrentlyInSubmenu = currentParent !== null;

  return {
    moveToNext,
    isCurrentlyInSubmenu,
    currentItems,
    currentParent,
    moveBack,
    reset,
  };
}

const Menu = (props) => {
  const {
    moveToNext,
    isCurrentlyInSubmenu,
    currentItems,
    currentParent,
    moveBack,
    close,
  } = props;

  return (
    <TransitionGroup>
      <CSSTransition
        classNames={isCurrentlyInSubmenu ? "submenu" : "menu"}
        timeout={400}
        key={currentParent?.value}
      >
        <div
          className={clsx(
            styles.navItems,
            isCurrentlyInSubmenu && styles.subnavItems
          )}
        >
          {isCurrentlyInSubmenu && (
            <div
              onClick={() => {
                moveBack();
              }}
              className={styles.backButton}
            >
              <LeftArrow />
              {currentParent?.value}
            </div>
          )}
          {currentItems?.length &&
            currentItems.map((item, index) => (
              <Item
                key={index}
                {...item}
                onMove={(item) => {
                  moveToNext(item);
                }}
                close={close}
                isSubNavItem={!!currentParent}
              />
            ))}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Sidebar = ({ isOpen, onClose, data }) => {
  const menu = useSubmenu(data);

  function close() {
    onClose();
    // using a timeout so that the reset animation isn't shown while closing
    setTimeout(() => {
      menu.reset();
    }, 800);
  }
  if (process.env.GHUB_DATADOG_SERVICE.indexOf("-oh-comms") > -1) {
    return (
      <div
        id="mobile-sidebar"
        className={clsx(styles.sidebar, isOpen && styles.open)}
      >
        <div className={styles.header}>
          <img
            alt="Columbia Care"
            src={logoIcon}
            className={styles.logoIcon}
            id={styles.logoIcon}
          />
          <div className={styles.cross}>
            <Cross
              onToggle={() => {
                close();
              }}
            />
          </div>
        </div>
        <Menu {...menu} close={close} />
      </div>
    );
  }
  return (
    <div
      id="mobile-sidebar"
      className={clsx(styles.sidebar, isOpen && styles.open)}
    >
      <div className={styles.header}>
        <div className={styles.locationIcon}>
          <GhubLink
            label={"Locations"}
            destination={"/locations"}
            attributes={{
              onClick: () => {
                close();
              },
            }}
            children={<LocationIcon stroke="white" />}
          />
        </div>
        <img alt="Columbia Care" src={logoIcon} className={styles.logoIcon} />
        <div className={styles.cross}>
          <Cross
            onToggle={() => {
              close();
            }}
          />
        </div>
      </div>
      <Menu {...menu} close={close} />
    </div>
  );
};

export default Sidebar;
