import React from "react";

import Navbar from "../navbar/Navbar/Navbar";

const Header = (props) => {
  return [
    props &&
      props.storybookArgs &&
      React.createElement(
        "style",
        { key: "style", type: "text/css" },
        props.storybookArgs.styles
      ),
      <Navbar bannerData={props.bannerData} key="navbar" />
  ];
};

Header.displayName = "Header";

export default Header;
