import React, { useState } from "react";
import parse from "html-react-parser";
import { getHtmlParseOptions } from "../../../../../../common/common";

import Button from "../../button/Button";
import { getSlug } from "../../../../../../common/helpers";
import * as modalStyles from "../modal.module.css";
import CCLogoWhite from "../../../images/col-care-logo-white.png";

const AgeGate = (props) => {
  const [agree, setAgree] = useState(false);
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const parseOptions = getHtmlParseOptions();

  const children = (
    <div className={modalStyles.AgeGateInner}>
      <img
        className={modalStyles.AgeGateLogo}
        src={CCLogoWhite}
        alt="columbia care logo"
      />
      <div className={modalStyles.AgeGateTextWrapper}>
        <h2 id="modal-title">{props.modalTitle || "Alert"}</h2>
        <div className={modalStyles.AgeGateLine} />
        {props.modalBodyText ? parse(props.modalBodyText, parseOptions) : ""}

        <label className={modalStyles.AgreeCheckbox}>
          <input type="checkbox" onChange={checkboxHandler} />I Agree
        </label>

        <Button
          theme={
            props.color_styles === "Dark" ? "transparentDark" : "transparent"
          }
          disabled={!agree}
          key={getSlug(props.confirmButtonText)}
          label={props.confirmButtonText}
          onClick={(e) => {
            props.confirmButtonCallback(Date.now());
            props.dismissModal();
          }}
        />
      </div>
    </div>
  );

  return children;
};

AgeGate.displayName = "AgeGate";

export default AgeGate;
