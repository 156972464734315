import React from "react";

import AgeGate from "./AgeGate";

export const getModalVariant = (props) => {
  if (props.variant === "age_gate") {
    const styles = {
      overlay: {
        backgroundColor: "rgba(0,0,0, 0.75)",
        zIndex: 100,
      },
      content: {
        inset: "unset",
        width: "90%",
        maxWidth: "1040px",
        padding: "80px",
        background: "#202245",
        position: "relative",
        top: "50%",
        left: "50%",
        bottom: "auto",
        right: "auto",
        WebkitTransform: "translate(-50%,-50%)",
        MsTransform: "translate(-50%,-50%)",
        transform: "translate(-50%,-50%)",
        border: "0",
        outline: "0",
        color: "white",
        borderRadius: "20px",
      },
    };
    const children = <AgeGate {...props} />;

    return [children, styles]
  }

  return null
};
