// @todo: move to common lib
export const getStructuredData = (type, state, content) => {
  const location = state.hydrate.locationData?.location;
  if (!location) {
    return null;
  }

  const seo = content.page_meta ? content.page_meta : content.seo;

  switch (type) {
    case "LocalBusiness":
      const latitude = location.address?.latitude;
      const longitude = location.address?.longitude;

      // @todo: do we want to put hours? seems redundant
      return {
        type: "application/ld+json",
        innerHTML: `{
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "url": "https://columbia.care/locations/${location.location_id}",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${location.address.city}",
                "addressRegion": "${location.address.state}",
                "streetAddress": "${location.address.street_address_1} ${
          location.address.street_address_2
        }",
                "postalCode": "${location.address.zip_code}",
                "addressCountry": "US"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "${parseFloat(latitude)}",
                "longitude": "${parseFloat(longitude)}"
            },
            "additionalType": ${JSON.stringify(seo.meta_additionaltype)},
            "sameAs": ${seo ? JSON.stringify(seo.meta_sameas) : ""},
            "image": [ "${""}" ],
            "name": "${"Columbia Care"}",
            "telephone": "${location.store_information.telephone}"
          }`,
      };
    default:
      return null;
  }
};
