import { useEffect } from "react";

export const useScript = ({
  id,
  url,
  type,
  link,
  setAsync,
  setDefer,
  innerHTML,
  charset,
  mountEle,
  critical,
  userConsentsToTracking,
  setEmbedScripts,
  embedScripts
}) => {
  useEffect(() => {
    // console.log('useScript updating', userConsentsToTracking)
    if (!id) {
      console.error('Please provide an ID for this tag.');
      return false;
    }
    const script =
      url || type === "script"
        ? document.createElement("script")
        : document.createElement("link");
    const mountElement = mountEle || "head"; // also you can pass 'head'

    script.id = id;

    if (url) script.src = url;
    if (setAsync) script.async = setAsync;
    if (setDefer) script.defer = setDefer;
    if (innerHTML) script.innerHTML = innerHTML;
    if (link) {
      script.rel = "stylesheet";
      script.href = link;
    }
    if (charset) script.charset = charset;

    const newEmbedScripts = {};

    // if (critical || userConsentsToTracking === true) {
      if (embedScripts[id] !== true) {
          console.log('Adding script', script)
          document.querySelector(mountElement).appendChild(script);
          newEmbedScripts[id] = true;
          const combinedEmbedScripts = Object.assign(embedScripts, newEmbedScripts);
          setEmbedScripts(combinedEmbedScripts);

        return () => {
          document.querySelector(mountElement).removeChild(script);
        };
      }
    // }
    // else {
    //   console.info('User did not consent to cookies/trackers for:', script)
      
    //   newEmbedScripts[id] = false;
    //   const combinedEmbedScripts = Object.assign(embedScripts, newEmbedScripts);
    //   setEmbedScripts(combinedEmbedScripts);
    // }
  }, [id, url, type, link, setAsync, setDefer, innerHTML, charset, mountEle, critical, userConsentsToTracking, setEmbedScripts, embedScripts]);
};