import React from "react";
import { useScript } from "../effects/useScript";

const AppScripts = (props) => {
  // const isDev = process.env.GHUB_CONTENTSTACK_ENVIRONMENT === 'comms_dev'

  // @todo: move this to Vault
  const ALPINE_IQ_ID = 1594;
  // const GTM_ID = 'GTM-KGXWKJF';

  // @todo: useRef()?

  // ATTENTION
  // MAKE SURE you add the ID to App.js
  // @todo: manifest for managed scripts

  // useScript({
  //   url: "//cdn.iubenda.com/cs/ccpa/stub.js",
  //   id: "iubenda-stub",
  //   setAsync: true,
  //   critical: true, // load this script regardless of user's consent status
  //    // this is trash
  //   userConsentsToTracking: props.userConsentsToTracking,
  //   setEmbedScripts: props.setEmbedScripts,
  //   embedScripts: props.embedScripts
  // });

  // useScript({
  //   url: "//cdn.iubenda.com/cs/iubenda_cs.js",
  //   id: "iubenda-cs",
  //   setAsync: true,
  //   charset: "UTF-8",
  //   critical: true,
  //   userConsentsToTracking: props.userConsentsToTracking,
  //   setEmbedScripts: props.setEmbedScripts,
  //   embedScripts: props.embedScripts
  // });

  // useScript({
  //   type: 'script',
  //   id: 'block-cookies-test',
  //   innerHTML: `void(0)`,
  //   userConsentsToTracking: props.userConsentsToTracking,
  //   setEmbedScripts: props.setEmbedScripts,
  //   embedScripts: props.embedScripts
  // })

  const alpineIqJs = `
    !function(i,c,n,e,a,t){i[a]&&i[a].q||(i[a]=function(){i[a].q.push([].slice.call(arguments))},i[a].q=[],(t=c.createElement("script")).async=1,t.src="https://lab.alpineiq.com/p/${ALPINE_IQ_ID}.js",t.id=a,c.head.appendChild(t))}(window,document,0,0,"aiq");
  `;

  useScript({
    type: "script",
    id: "alpine-iq-pixel",
    innerHTML: alpineIqJs,
    userConsentsToTracking: props.userConsentsToTracking,
    setEmbedScripts: props.setEmbedScripts,
    embedScripts: props.embedScripts,
  });

  const accessibeJs = `(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#202245', triggerColor : '#087cbf', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'center', triggerOffsetX : 10, triggerOffsetY : 0, triggerRadius : '50%' } }); }; h.appendChild(s); })();`;

  useScript({
    type: "script",
    id: "accessibe-embed",
    innerHTML: accessibeJs,
    userConsentsToTracking: props.userConsentsToTracking,
    setEmbedScripts: props.setEmbedScripts,
    embedScripts: props.embedScripts,
  });

  // useScript({
  //   type: 'script',
  //   id: 'hs-script-loader',
  //   url: 'https://js.hs-scripts.com/5757798.js',
  //   userConsentsToTracking: props.userConsentsToTracking,
  //   setEmbedScripts: props.setEmbedScripts,
  //   embedScripts: props.embedScripts
  // });

  // let contentsquareJs;
  // // if (!isDev) {
  //   contentsquareJs = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //     })(window,document,'script','dataLayer','${ GTM_ID }');
  //   `;
  // // }
  // // else {
  // //   contentsquareJs = ``
  // // }

  // useScript({
  //   type: 'script',
  //   id: 'contentsquare-embed',
  //   innerHTML: contentsquareJs,
  //   userConsentsToTracking: props.userConsentsToTracking,
  //   setEmbedScripts: props.setEmbedScripts,
  //   embedScripts: props.embedScripts
  // });

  // if (!isDev) {
  useScript({
    url: "https://t.contentsquare.net/uxa/bf64b2d0cb8ea.js",
    id: "contentsquare-embed",
    setAsync: true,
    userConsentsToTracking: props.userConsentsToTracking,
    setEmbedScripts: props.setEmbedScripts,
    embedScripts: props.embedScripts,
  });
  // }

  return <></>;
};

export default AppScripts;
