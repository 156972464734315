// import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'whatwg-fetch';

import reduxWrapper from "./src/state/reduxWrapper";

export const wrapPageElement = ({ props, element }) => {
  return reduxWrapper(props, element)
}

window._iub = window._iub || [];
window._iub.csConfiguration = {"consentOnContinuedBrowsing":false,"floatingPreferencesButtonDisplay":"bottom-right","gdprAppliesGlobally":false,"lang":"en","siteId":2641465,"cookiePolicyId":84255758, "banner":{ "acceptButtonDisplay":true,"backgroundOverlay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"logo":null,"position":"float-bottom-center","rejectButtonDisplay":true }};

// other polyfills
smoothscroll.polyfill();

document.addEventListener("readystatechange", (event) => {
  if (event.target.readyState === "complete") {
    // window.scrollTo({ top: 0, behavior: "smooth" });
  }
});

// polyfills for Jane
if (!window.ResizeObserver) {
  window.ResizeObserver = require("resize-observer-polyfill");
}

if (!window.IntersectionObserver) {
  window.IntersectionObserver = require("intersection-observer-polyfill");
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const segs = location.pathname.split('/');

  // @todo: gotta get unique IDs under control; just clean this up
  if (segs[3] && segs[3].indexOf('page-') === 0) {
    window.setTimeout(
      () =>  {
        const sectionHero = document.querySelectorAll('header') && document.querySelectorAll('header')[1] 
          ? document.querySelectorAll('header')[1] : null;
        if (sectionHero) {
          window.scrollTo({ top: sectionHero.offsetHeight - 30 })
        }
      },
    100);
  } 
  else if (location.pathname === '/news/media-coverage' || location.pathname === '/news/press-release') {
    window.setTimeout(
      () =>  {
        const sectionHero = document.querySelectorAll('header') && document.querySelectorAll('header')[1] 
          ? document.querySelectorAll('header')[1] : null;
        if (sectionHero) {
          window.scrollTo({ top: sectionHero.offsetHeight - 30 })
        }
      },
    100);
  } 
  else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      100
    );
  }
  
  return false;
};