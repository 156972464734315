const ohdata = [
  {
    value: "DAYTON",
    href: "/locations/oh-dayt-wayne-333",
    items: [],
  },
  {
    value: "LOGAN",
    href: "/locations/oh-loga-wmain-245",
    items: [],
  },
  {
    value: "MARIETTA",
    items: [],
    href: "/locations/oh-mari-greene-414",
  },
  {
    value: "MONROE",
    items: [],
    href: "/locations/oh-monr-nmain-300",
  },
];

export default ohdata;
